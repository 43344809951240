/* typing */
.ideabot-bot-message {
  display: flex;
  flex-direction: row;
  background-color: #6c757d;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0px 20px 20px 20px;
}

.ideabot-typing-wrapper {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  .ideabot-avatar-wrapper{
    display: flex;
    flex-direction: row;
    position: relative;
    img{
      width:25px;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  .ideabot-bot-message{
    display: flex;
    flex-direction: row;
    background-color: #D4FFF9;
    padding: 5.5px 15px;
    margin: 10px 0px 0px 18px;
    border-radius: 0px 24px 24px 24px;
    .bouncing-loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
    }

    .bouncing-loader>div {
      width: 10px;
      height: 10px;
      margin: 5px 5px;
      border-radius: 50%;
      background-color: #00EFCF;
      opacity: 1;
      animation: bouncing-loader 0.6s infinite alternate;
    }

    @keyframes bouncing-loader {
      to {
        opacity: 0.1;
        transform: translateY(-8px);
      }
    }

    .bouncing-loader>div:nth-child(2) {
      animation-delay: 0.2s;
    }

    .bouncing-loader>div:nth-child(3) {
      animation-delay: 0.4s;
    }

    }  
}
