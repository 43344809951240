html {
	background-color: #f6fcff;
}
.primary-btn {
	background-color: #040045;
	margin: 10px;
	border: none;
	border-radius: 25px;
	padding: 10px 25px;
	color: white;
	font-size: 12px;
	line-height: normal;
	font-family: "Manrope", sans-serif;
	font-weight: 500;
	transition: transform 0.2s, background-color 0.2s;
}

.primary-btn:hover {
	background-color: #060241;
	transform: scale(1.02);
}

.transparent {
	background-color: transparent;
}

// .header-container {
// 	padding: 20px 0px;
// 	background-color: #020427;
// 	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
// 	position: fixed;
// 	top: 0;
// 	z-index: 100;
// 	height: 100px;
// 	width: 100%;
// 	display: flex;
// 	flex-direction: row;
// 	.header-wrapper {
// 		display: flex;
// 		flex-direction: row;
// 		.logo {
// 			display: flex;
// 			flex: 0.2;
// 			img {
// 				width: 150px;
// 				cursor: pointer;
// 			}
// 		}
// 		.menu {
// 			display: flex;
// 			flex: 0.8;
// 			flex-direction: row;
// 			justify-content: flex-end;
// 			.menu-list {
// 				display: flex;
// 				flex-direction: row;
// 				justify-content: center;
// 				align-items: center;
// 				ul {
// 					list-style: none;
// 					display: flex;
// 					flex-direction: row;
// 					justify-content: center;
// 					align-items: center;
// 					padding-right: 40px;
// 					li {
// 						a {
// 							padding: 10px 0px;
// 							margin: 0px 10px;
// 							color: white;
// 							font-family: "Manrope", sans-serif;
// 							line-height: 1.5;
// 							font-weight: 500;
// 							cursor: pointer;
// 							font-size: 14px;
// 							text-transform: uppercase;
// 						}
// 					}
// 					.active {
// 						border-bottom: 2px solid #00efcf;
// 						transition: border-bottom 0.1s;
// 						transition-timing-function: ease-out;
// 						// transition-delay: 0.1s;
// 						transform: 0.2s;
// 					}
// 				}
// 			}
// 			nav {
// 				position: absolute;
// 				top: 0;
// 				left: 0;
// 				bottom: 0;
// 				width: 100%;
// 				height: 100vh;
// 				ul {
// 					padding: 25px;
// 					position: relative;
// 					// top: 100px;
// 					width: 230px;
// 					padding-top: 80px;
// 					// padding-bottom: 160px;
// 					li {
// 						list-style: none;
// 						margin-bottom: 20px;
// 						display: flex;
// 						align-items: center;
// 						cursor: pointer;
// 						.icon-placeholder {
// 							width: 40px;
// 							height: 40px;
// 							border-radius: 50%;
// 							flex: 40px 0;
// 							margin-right: 20px;
// 						}
// 						.text-placeholder {
// 							border-radius: 5px;
// 							// width: 200px;
// 							// height: 20px;
// 							flex: 1;
// 							p {
// 								color: white;
// 								text-transform: uppercase;
// 								font-family: "Manrope", sans-serif;
// 								line-height: 1.5;
// 								font-weight: 500;
// 								cursor: pointer;
// 								font-size: 14px;
// 							}
// 						}
// 					}
// 				}
// 				.toggle-btn {
// 					outline: none;
// 					border: none;
// 					-webkit-user-select: none;
// 					-moz-user-select: none;
// 					-ms-user-select: none;
// 					cursor: pointer;
// 					position: absolute;
// 					top: 16px;
// 					right: 0px;
// 					width: 50px;
// 					height: 50px;
// 					border-radius: 50%;
// 					background: transparent;
// 					display: none;
// 				}
// 			}
// 			.closed {
// 				height: auto;
// 				display: none;
// 			}
// 			.background {
// 				position: absolute;
// 				top: 0;
// 				left: 0;
// 				bottom: 0;
// 				width: 100%;
// 				background: #020427;
// 				display: flex;
// 				flex-direction: column;
// 				// justify-content: space-between;
// 				.mobile-logo {
// 					padding: 24.5px 0px 20px 25px;
// 					img {
// 						width: 85px;
// 					}
// 				}
// 				.line {
// 					height: 0.5px;
// 					width: 100%;
// 					background-color: #eee;
// 				}
// 				.button-wrapper {
// 					display: flex;
// 					flex-direction: column;
// 					padding-right: 25px;
// 					padding-left: 25px;
// 					margin-top: 180px;
// 					.wrapper {
// 						padding-top: 20px;
// 						padding-bottom: 80px;
// 						display: flex;
// 						flex-direction: row;
// 						button:nth-of-type(1) {
// 							margin-left: 0px;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.hero-section {
	position: relative;
	#tsparticles {
		height: calc(100vh - 100px);
	}
	.overlay-container {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		z-index: 9;
		.overlay-wrapper {
			position: absolute;
			h1 {
				color: white;
				font-size: 55px;
				line-height: 1.1;
				font-weight: 700;
				font-family: "Manrope", sans-serif;
			}
			h4 {
				color: white;
				font-size: 22px;
				line-height: 1.5;
				text-transform: lowercase;
				opacity: 0.8;
				padding-bottom: 20px;
				font-weight: 400;
				padding-top: 10px;
				font-family: "Manrope", sans-serif;
			}
			button {
				font-size: 22px;
				line-height: 1.5;
				height: 60px;
				width: 185px;
				margin-top: 15px;
				font-weight: 500;
				border-radius: 50px;
				letter-spacing: 0.5px;
				background-color: transparent;
			}
		}
	}
}

.section-test {
	padding: 80px 0px;
	background-color: #020427;
	position: relative;
	// height: 950px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.section-one-left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-right: 100px;
		// padding-left: 200px;
		.title-top {
			font-size: 12px;
			color: white;
			opacity: 0.6;
			padding-bottom: 0px;
		}
		h2 {
			color: white;
			font-size: 40px;
			line-height: 49.2px;
			font-weight: 700;
			font-family: "Manrope", sans-serif;
			padding-bottom: 20px;
			text-transform: uppercase;
		}
		p {
			color: white;
			padding-bottom: 20px;
		}
		button {
			margin-left: 0;
		}
	}
	.section-one-right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-left: 40px;
		// position: relative;
		img {
			// margin-top: 185px;
			// width: 100%;
			// height: 950px;
			// position: absolute;
			width: 100%;
			right: 0;
			top: 100px;
			object-fit: cover;
			object-position: 50% 50%;
			z-index: 9;
		}
	}
}

.section-test-2 {
	padding: 80px 0px;
	background-color: #020427;
	position: relative;
	// height: 1120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.section-one-left {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			// height: 950px;
			// position: absolute;
			width: 100%;
			left: 0;
			top: 230px;
			object-fit: cover;
			object-position: 50% 50%;
			z-index: 9;
		}
	}
	.section-one-right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 40px;
		padding-right: 100px;
		.title-top {
			font-size: 12px;
			color: white;
			opacity: 0.6;
			padding-bottom: 0px;
		}
		h2 {
			color: white;
			font-size: 40px;
			line-height: 49.2px;
			font-weight: 700;
			font-family: "Manrope", sans-serif;
			padding-bottom: 20px;
			text-transform: uppercase;
		}
		p {
			color: white;
			padding-bottom: 20px;
		}
		button {
			margin-left: 0;
		}
	}
}

.section-one {
	padding: 60px 0px;
	background-color: #06093f;
	display: flex;
	flex-direction: column;
	flex: 1;
	.section-one-left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 200px;
		h2 {
			color: white;
			font-size: 40px;
			line-height: 49.2px;
			font-weight: 700;
			font-family: "Manrope", sans-serif;
			padding-bottom: 20px;
		}
		p {
			color: white;
			padding-bottom: 20px;
		}
		button {
			margin-left: 0;
		}
	}
	.section-one-right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			width: 50%;
		}
	}
}

.section-two {
	padding: 230px 0px;
	background-color: #020427;
	.section-one-left {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 50%;
		}
	}
	.section-one-right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 200px;
		h2 {
			color: white;
			font-size: 40px;
			line-height: 49.2px;
			font-weight: 700;
			font-family: "Manrope", sans-serif;
			padding-bottom: 20px;
		}
		p {
			color: white;
			padding-bottom: 20px;
		}
		button {
			margin-left: 0;
		}
	}
}

.footer {
	padding: 50px 0px;
	background-color: #020427;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	.logo {
		img {
			width: 150px;
		}
	}
	.address,
	.social {
		p {
			font-family: "Manrope", sans-serif;
			font-size: 14px;
			line-height: 19px;
			color: #a6a6a6;
			font-weight: 600;
		}
		ul {
			padding-top: 10px;
			li {
				a {
					display: flex;
					flex-direction: row;
					justify-items: center;
					align-items: center;
					padding: 5px 0px;
					color: white;
					font-family: "Manrope", sans-serif;
					font-size: 14px;
					cursor: pointer;
					img {
						width: 15px;
						height: 15px;
						margin-right: 8px;
					}
				}
			}
		}
	}
	.copyright {
		p {
			font-family: "Manrope", sans-serif;
			font-size: 14px;
			color: white;
		}
	}
}

.main-container {
	// height: 100vh;
	overflow-y: scroll;
	overflow: hidden;
	background-color: #020427;
	// scroll-snap-type: y mandatory;
}

.header-container .header-wrapper .menu button:nth-of-type(2) {
	margin-right: 0;
}

.login-page {
	background-color: white;
	.login-left {
	}
	.login-right {
		padding: 30px 0px;
		.logo {
			padding-bottom: 15px;
			img {
				width: 180px;
			}
		}
		.login-form {
			width: 50%;
			text-align: center;
			.form-title {
				font-size: 20px;
				line-height: 54px;
				font-weight: 500;
				font-family: "Manrope", sans-serif;
				color: #040045;
			}
			form {
				.form-data {
					input {
						display: block;
						box-sizing: border-box;
						width: 100%;
						border-radius: 4px;
						font-family: "Manrope", sans-serif;
						border: 1px solid #d9d9d9;
						padding: 10px 15px;
						margin-bottom: 10px;
						font-size: 14px;
						height: 55px;
					}

					label {
						line-height: 2;
						text-align: left;
						display: block;
						margin-top: 20px;
						color: #464a53;
						font-family: "Manrope", sans-serif;
						font-size: 14px;
						font-weight: 200;
					}
					:focus-visible {
						outline: none;
					}
				}

				.password-container {
					.left {
						input {
							padding: 0px;
							font-family: "Manrope", sans-serif;
						}
						label {
							font-family: "Manrope", sans-serif;
							font-size: 14px;
							color: #53575f;
						}
						:focus-visible {
							outline: none;
						}
					}
					.right {
						a {
							font-family: "Manrope", sans-serif;
							font-size: 14px;
							color: #53575f;
						}
					}
				}
				.submit-container {
					padding: 25px 0px;
					input {
						border: 4px solid #00efcf;
						width: 100%;
						background-color: rgba(0, 255, 221, 0.25);
						padding: 10px 25px;
						font-family: "Manrope", sans-serif;
						border-radius: 8px;
					}
				}
				.login-bottom {
					padding-top: 30px;
					p {
						color: #828282;
						font-family: "Manrope", sans-serif;
						font-size: 14px;
						a {
							color: #040044;
							text-decoration: underline;
							padding-left: 2px;
						}
					}
				}
			}
		}
	}
}

.main-container {
	margin-top: 75px;
}

//Component
.auth-form {
	border: none;
	width: 100%;
	background-color: #040045;
	padding: 10px 25px;
	font-family: "Manrope", sans-serif;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px;
	color: white;
	font-size: 16px;
	height: 55px;
}

.error-handler {
	font-size: 12px;
	font-family: "Manrope", sans-serif;
	line-height: 1.5;
	color: red;
	height: 20px;
}

.sucussfull-hanlde {
	font-size: 12px;
	font-family: "Manrope", sans-serif;
	line-height: 1.5;
	color: green;
	height: 20px;
}

.btn-status {
	width: 100px;
	padding: 10px 15px;
	border-radius: 48px;
	font-size: 12px;
	font-family: "Manrope", sans-serif;
	color: white;
}

.btn-status:hover {
	transform: scale(1.01);
}

.btn-action {
	width: 90px;
	padding: 10px 15px;
	border-radius: 8px;
	font-size: 12px;
	font-family: "Manrope", sans-serif;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border: 1px solid #040044;
	color: #040044;
	margin-right: 10px;
	transition: transform 0.2s, background-color 0.2s;
	img {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}

.btn-action:hover {
	background-color: #eee;
	transform: scale(1.01);
}
.left-side-bar {
	.logo {
		img {
			width: 150px;
		}
	}
}

.admin-content {
	background-color: #f5fcff;
	.title {
		padding-bottom: 40px;
		h2 {
			font-size: 12px;
			font-family: "Manrope", sans-serif;
			line-height: 1.5;
			border-bottom: 1px solid black;
		}
	}
	.web-url-container {
		padding-bottom: 30px;
		form {
			max-width: 100%;

			label {
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				padding-bottom: 20px;
			}
			p {
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				padding-top: 10px;
				padding-bottom: 15px;
			}
			.input-wrapper {
				input[type="text"] {
					padding: 10px 15px;
					border: 1px solid #e8e8e8;
					border-radius: 8px;
					font-size: 14px;
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					width: 50%;
					height: 42px;
				}
				.auth-form {
					background-color: #040044;
					border-radius: 8px;
					font-size: 12px;
					font-family: "Manrope", sans-serif;
					color: white;
					width: 100px;
					margin-left: 25px;
					border: none;
					height: 42px;
				}
			}
		}
		label {
			font-size: 12px;
			font-family: "Manrope", sans-serif;
			line-height: 1.5;
			padding-bottom: 20px;
		}
		.input-wrapper {
			label {
				font-weight: 700;
			}
		}
	}
	.file-upload {
		padding-bottom: 30px;
		form {
			max-width: 100%;
			label {
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				padding-bottom: 20px;
			}
			input[type="file"] {
				background-color: #ffffff;
				padding: 15px 20px;
				border-radius: 8px;
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				border: 1px solid #e8e8e8;
				cursor: pointer;
			}
			input[type="file"]::file-selector-button {
				background-color: #040044;
				padding: 14px 16px;
				border-radius: 8px;
				border: none;
				color: white;
				margin-right: 20px;
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				cursor: pointer;
			}
		}

		.drag-drop-wrapper {
			.drag-drop-container {
				background-color: #ffffff;
				padding: 40px 60px;
				border-radius: 8px;
				border: 1px solid #e8e8e8;
				.drag-drop {
					background-color: #ffffff;
					padding: 20px 20px;
					border-radius: 8px;
					font-size: 12px;
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					cursor: pointer;
					border-color: #040044;
					border-width: 1px;
					img {
						width: 40px;
						margin-right: 20px;
					}
					p {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
					}
				}
			}
		}
	}
	.file-upload-table {
		padding-bottom: 30px;
		.table-header {
			font-size: 12px;
			font-family: "Manrope", sans-serif;
			line-height: 1.5;
			padding-bottom: 20px;
		}
		table {
			border: 1px solid #e8e8e8;
			min-width: 550px;
			overflow-x: auto;
			thead {
				border: 1px solid #e8e8e8;
				tr {
					th {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						font-weight: 400;
						color: #53575f;
						th:first-child {
							text-align: left;
							max-width: 100px;
						}
					}
				}
			}
			tbody {
				tr {
					border: 1px solid #e8e8e8;
					text-align: center;
					td {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						text-align: left;
						color: #333333;
					}
					.source-name {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 100px;
						display: inline-block;
						font-size: 12px;
						cursor: pointer;
					}
				}
			}
		}
		.no-data-found {
			min-height: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: white;
			p {
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				text-align: center;
				color: #333333;
				font-size: 12px;
			}
		}
	}
}

.main-content {
	margin-top: 75px;
}

.main-preview {
	background-color: #f5fcff;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	.preview-header {
		padding: 14px 25px;
		background-color: white;
		.logo {
			padding-left: 5px;
			img {
				width: 120px;
			}
		}
		.user-details {
			flex-direction: row;
			p {
				padding-right: 20px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				color: #333333;
				font-size: 14px;
			}
		}
	}

	.preview-controller {
		.preview-content {
			.file-upload {
				padding-bottom: 30px;
				form {
					max-width: 100%;
					label {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						padding-bottom: 20px;
					}
					input[type="file"] {
						background-color: #ffffff;
						padding: 15px 20px;
						border-radius: 8px;
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						border: 1px solid #e8e8e8;
						cursor: pointer;
					}
					input[type="file"]::file-selector-button {
						background-color: #040044;
						padding: 14px 16px;
						border-radius: 8px;
						border: none;
						color: white;
						margin-right: 20px;
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						cursor: pointer;
					}
					:focus-visible {
						outline: none;
					}
				}

				.drag-drop-wrapper {
					.drag-drop-container {
						background-color: #ffffff;
						padding: 40px 45px;
						border-radius: 8px;
						border: 1px solid #e8e8e8;
						.drag-drop {
							background-color: #ffffff;
							padding: 20px 20px;
							border-radius: 8px;
							font-size: 12px;
							font-family: "Manrope", sans-serif;
							line-height: 1.5;
							cursor: pointer;
							border-color: #040044;
							border-width: 1px;
							img {
								width: 40px;
								margin-right: 20px;
							}
							p {
								font-size: 12px;
								font-family: "Manrope", sans-serif;
								line-height: 1.5;
							}
						}
					}
				}
			}
			.file-upload-table {
				padding-bottom: 30px;
				.table-header {
					font-size: 12px;
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					padding-bottom: 20px;
				}
				table {
					border: 1px solid #e8e8e8;
					// overflow-x: auto;
					thead {
						border: 1px solid #e8e8e8;
						tr {
							th {
								font-size: 12px;
								font-family: "Manrope", sans-serif;
								line-height: 1.5;
								font-weight: 400;
								color: #53575f;
								th:first-child {
									text-align: left;
									max-width: 100px;
								}
							}
						}
					}
					tbody {
						tr {
							border: 1px solid #e8e8e8;
							text-align: center;
							td {
								font-size: 12px;
								font-family: "Manrope", sans-serif;
								line-height: 1.5;
								text-align: left;
								color: #333333;

								.source-name-url {
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									width: 200px;
									display: inline-block;
									font-size: 12px;
									cursor: pointer;
								}
							}
							.source-name {
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								width: 100px;
								display: inline-block;
								font-size: 12px;
								cursor: pointer;
							}
						}
					}
				}
				.no-data-found {
					min-height: 100px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background-color: white;
					p {
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						text-align: center;
						color: #333333;
						font-size: 12px;
					}
				}
			}
			.web-url-container {
				padding-bottom: 30px;
				form {
					max-width: 100%;

					label {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						padding-bottom: 20px;
					}
					p {
						font-size: 12px;
						font-family: "Manrope", sans-serif;
						line-height: 1.5;
						padding-top: 10px;
						padding-bottom: 15px;
					}
					.input-wrapper {
						input[type="text"] {
							padding: 10px 15px;
							border: 1px solid #e8e8e8;
							border-radius: 8px;
							font-size: 14px;
							font-family: "Manrope", sans-serif;
							line-height: 1.5;
							width: 50%;
							height: 42px;
						}
						:focus-visible {
							outline: none;
						}
						.auth-form {
							background-color: #040044;
							border-radius: 8px;
							font-size: 12px;
							font-family: "Manrope", sans-serif;
							color: white;
							width: 100px;
							margin-left: 25px;
							border: none;
							height: 42px;
						}
					}
				}
				label {
					font-size: 12px;
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					padding-bottom: 20px;
				}
				.input-wrapper {
					label {
						font-weight: 700;
					}
				}
			}
		}
		// ::-webkit-scrollbar {
		// 	display: none;
		// }

		.preview-chat {
			width: 50%;
			padding: 20px;
		}
		.preview-chat-mobile {
			width: 100%;
		}
	}
}

.Toastify__toast-body {
	div {
		font-family: "Manrope", sans-serif;
		line-height: 1.5;
		text-align: center;
		color: #333333;
		font-size: 14px;
		font-weight: 500;
	}
}

.header-top {
	padding: 10px 25px;
	display: flex;
	flex-direction: row;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	.logo {
		display: flex;
		flex: 0.5;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 5px;
		justify-content: center;
		img {
			width: 120px;
		}
	}
	.user-details {
		display: flex;
		flex: 0.5;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		p {
			padding-right: 20px;
			font-family: "Manrope", sans-serif;
			line-height: 1.5;
			color: #333333;
			font-size: 14px;
		}
	}
}

.error-container {
	.logo-wrapper {
		padding-bottom: 20px;
		img {
			width: 180px;
		}
	}
	.error-wrapper {
		img {
			width: 300px;
		}
	}
	.error-message {
		font-family: "Manrope", sans-serif;
		line-height: 1.5;
		color: #040044;
		font-size: 28px;
		font-weight: 700;
	}
	.primary-btn {
		margin-top: 20px;
		font-size: 16px;
	}

	.error-instruction {
		text-align: center;
		p {
			font-size: 12px;
			font-family: "Manrope", sans-serif;
			line-height: 1.5;
			padding-bottom: 20px;
			padding-top: 10px;
			color: #020427;
		}
		ul {
			margin: 0px;
			padding: 0px;
			margin-bottom: 10px;
			li {
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				color: #020427;
			}
		}
	}
}

.closed {
	height: auto;
	display: block;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #020427;
	display: flex;
	flex-direction: column;
	z-index: 10;
	// justify-content: space-between;
	.mobile-logo {
		padding: 15.5px 0px 17px 35px;
		img {
			width: 95px;
		}
	}
	.line {
		height: 0.2px;
		width: 100%;
		background-color: #eee;
	}
	.button-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding-right: 25px;
		padding-left: 35px;
		// margin-top: 100px;
		// align-items: space-around;
		align-items: flex-start;
		justify-content: flex-end;
		// height: 100vh;
		.wrapper {
			padding-top: 30px;
			padding-bottom: 30px;
			display: flex;
			flex: 1;
			flex-direction: row;
			button:nth-of-type(1) {
				margin-left: 0px;
			}
			button {
				background-color: white;
				color: #040044;
				height: 36px;
			}
		}
		.mobile-footer {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: flex-end;
			p {
				font-size: 12px;
				font-family: "Manrope", sans-serif;
				line-height: 1.5;
				color: white;
				padding: 30px 0px;
				font-weight: 800;
			}
		}
	}
}

.toggle-btn {
	outline: none;
	border: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 30px;
	width: 40px;
	height: 40px;
	background: #020427;
	z-index: 10;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.recharts-tooltip-wrapper
	.recharts-default-tooltip
	.recharts-tooltip-item-list
	li
	.recharts-tooltip-item-value {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 130px;
	display: block;
}

.recharts-wrapper,
.recharts-surface {
	@media print {
		width: calc(100%) !important;
	}
}
.main-preview
	.preview-controller
	.preview-content
	.file-upload-table
	.suburl-table-container {
	max-width: 650px;
}

/**************  Media Query for responsive *****************/

@media screen and (max-width: 1440px) {
	.login-page .login-right .login-form {
		width: 60%;
	}

	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 600px;
	}
}

/* For Desktop View */
@media screen and (max-width: 1280px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper {
		max-width: 580px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 580px;
	}
	.main-preview .preview-header .user-details p {
		font-size: 10px;
		padding-right: 10px;
	}
	.user-details .primary-btn {
		font-size: 10px;
		padding: 7px 15px;
	}
}
/* For Desktop View */
@media screen and (max-width: 1240px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper {
		max-width: 510px;
	}
}

// and (max-width: 1024px)
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.header-container .header-wrapper .menu .menu-list ul {
		padding-right: 10px;
	}

	.header-container .header-wrapper .menu .menu-list ul li a {
		font-size: 12px;
		margin: 0px 7px;
	}
	.header-container .header-wrapper .logo {
		flex: 0.15;
	}
	.header-container .header-wrapper .menu {
		flex: 0.85;
	}
	.primary-btn {
		padding: 7px 20px;
		font-size: 12px;
		height: 46px;
	}
	// .header-container .header-wrapper .logo img {
	// 	width: 100px;
	// }
	// .header-container .header-wrapper .menu button:nth-of-type(2) {
	// 	margin-right: 0;
	// }
	// .header-container .header-wrapper .menu button:nth-of-type(1) {
	// 	margin-right: 5px;
	// }
	// .hero-section #tsparticles {
	// 	height: 550px;
	// }
	// .hero-section .overlay-container .overlay-wrapper h1 {
	// 	font-size: 38px;
	// }
	// .hero-section .overlay-container .overlay-wrapper h4 {
	// 	font-size: 16px;
	// }
	// .hero-section .overlay-container .overlay-wrapper button {
	// 	font-size: 16px;
	// 	width: 150px;
	// 	height: 45px;
	// }
	// .section-test .section-one-left h2,
	// .section-test-2 .section-one-right h2 {
	// 	font-size: 30px;
	// 	line-height: 1.2;
	// }
	// .section-test .section-one-left p,
	// .section-test-2 .section-one-right p {
	// 	font-size: 16px;
	// 	line-height: 1.2;
	// 	font-weight: 300;
	// }

	// .section-test .section-one-left,
	// .section-test-2 .section-one-right {
	// 	padding-right: 20px;
	// }
	// .footer {
	// 	padding-left: 25px;
	// 	padding-right: 25px;
	// }
	// .footer .logo {
	// 	padding-bottom: 30px;
	// }
	// .footer .logo img {
	// 	width: 85px;
	// }
	// .footer .social {
	// 	padding-top: 20px;
	// 	padding-bottom: 20px;
	// }
	// .footer .text-right {
	// 	text-align: center;
	// 	padding-top: 40px;
	// }
}

@media screen and (max-width: 1024px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 490px;
	}
}

@media screen and (max-width: 968px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 440px;
	}
}

@media screen and (max-width: 800px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 500px;
	}
}

/* For Tablet View */
@media screen and (max-width: 768px) {
	.header-container .header-wrapper .logo {
		flex: 0.5;
	}
	.header-container .header-wrapper .logo img {
		width: 85px;
	}
	.header-container .header-wrapper .menu {
		flex: 0.5;
	}
	.header-container .header-wrapper .menu .menu-list {
		display: none;
	}
	.primary-btn {
		padding: 7px 20px;
		font-size: 12px;
		height: 46px;
	}
	.header-container .header-wrapper .menu .closed {
		display: block;
	}
	.hero-section #tsparticles {
		height: 450px;
	}

	.hero-section .overlay-container .overlay-wrapper h1 {
		font-size: 36px;
	}
	.hero-section .overlay-container .overlay-wrapper h4 {
		font-size: 14px;
	}
	.hero-section .overlay-container .overlay-wrapper {
		padding: 0px 20px;
	}
	.hero-section .overlay-container .overlay-wrapper button {
		font-size: 16px;
		width: 150px;
		height: 45px;
	}
	.section-test {
		padding: 0px 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.section-test .section-one-left {
		padding-bottom: 20px;
		padding-right: 0px;
	}

	.section-test-2 {
		padding: 0px 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.section-test-2,
	.section-test {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.section-test-2 .section-one-right,
	.section-test .section-one-left {
		align-items: center;
	}
	.section-test .section-one-left p,
	.section-test-2 .section-one-right p {
		font-size: 12px;
		text-align: center;
	}
	.section-test .section-one-right,
	.section-test-2 .section-one-right {
		padding-left: 0px;
		padding-bottom: 40px;
	}

	.section-test-2 .section-one-right {
		padding-right: 0px;
	}

	.section-test-2 .section-one-left {
		padding-bottom: 40px;
	}
	.section-test-2 .section-one-right h2,
	.section-test .section-one-left h2 {
		font-size: 28px;
		line-height: 1.2;
		padding-bottom: 30px;
		text-align: center;
	}
	.footer {
		padding-left: 25px;
		padding-right: 25px;
	}
	.footer .logo {
		padding-bottom: 30px;
	}
	.footer .logo img {
		width: 85px;
	}
	.footer .social {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.footer .text-right {
		text-align: center;
		padding-top: 40px;
	}
	.footer .text-right p {
		font-size: 12px;
	}
	.footer .address ul li,
	.footer .social ul li {
		font-size: 12px;
	}
	.header-container .header-wrapper .menu nav {
		display: block;
	}
	.header-container .header-wrapper .menu .primary-btn {
		display: none;
	}
	.header-container .header-wrapper .menu .open .background button {
		display: block;
	}
	.header-container .header-wrapper .menu nav .toggle-btn {
		display: block;
	}
	.header-container {
		height: 75px;
	}

	.login-page .login-right .login-form {
		width: 86%;
	}
	.login-page .login-right .login-form form .password-container .left label {
		font-size: 12px;
	}

	.main-preview .preview-controller .preview-chat {
		width: 100%;
		padding: 35px;
		height: 100vh;
		// margin-top: 30px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload
		.drag-drop-wrapper
		.drag-drop-container {
		padding: 20px 30px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		table
		tbody
		tr
		td {
		padding: 10px 15px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		table
		thead
		tr
		th {
		padding: 10px 15px;
	}

	.btn-status {
		width: 75px;
		padding: 6px 15px;
		font-size: 10px;
	}
	.btn-action {
		width: 75px;
		padding: 8px 7px;
		font-size: 10px;
	}
	.btn-action img {
		width: 12px;
		height: 12px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 590px;
	}
	.toggle-btn {
		display: flex;
	}
	.main-preview .preview-header .user-details {
		display: none;
	}
	.main-preview .preview-header .logo img {
		width: 100px;
	}
	.error-container .error-message {
		font-size: 18px;
	}

	.error-container .logo-wrapper img {
		width: 100px;
	}
	.error-container .error-wrapper img {
		width: 150px;
	}
	.login-page .login-right .logo img {
		width: 100px;
	}
	.login-page .login-right .login-form .form-title {
		font-size: 16px;
	}
	.login-page .login-right .login-form form .form-data input {
		height: 46px;
	}
	.login-page .login-right .login-form form .form-data label {
		font-size: 12px;
	}
	.login-page .login-right .login-form form .password-container .right a {
		font-size: 12px;
	}
	.login-page .login-right .login-form form .login-bottom p {
		font-size: 12px;
	}
	.recharts-tooltip-wrapper
		.recharts-default-tooltip
		.recharts-tooltip-label {
		font-size: 10px;
	}
	.recharts-tooltip-wrapper
		.recharts-default-tooltip
		.recharts-tooltip-item-list
		li
		.recharts-tooltip-item-value {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 150px;
		display: block;
	}
}

@media screen and (max-width: 740px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 530px;
	}
}

@media screen and (max-width: 640px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 480px;
	}
}

@media screen and (max-width: 540px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 445px;
	}
}

@media screen and (max-width: 480px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 420px;
	}
}

@media screen and (max-width: 420px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 327px;
	}
}

@media screen and (max-width: 380px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 310px;
	}
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 340px) {
}

@media screen and (max-width: 320px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 260px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload
		.drag-drop-wrapper
		.drag-drop-container {
		padding: 15px 15px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload
		.drag-drop-wrapper
		.drag-drop-container
		.drag-drop
		img {
		width: 30px;
	}
	.main-preview
		.preview-controller
		.preview-content
		.file-upload
		.drag-drop-wrapper
		.drag-drop-container
		.drag-drop {
		padding: 15px;
	}
}

@media screen and (max-width: 300px) {
}

@media screen and (max-width: 280px) {
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.table-wrapper,
	.main-preview
		.preview-controller
		.preview-content
		.file-upload-table
		.suburl-table-container {
		max-width: 220px;
	}
}

@media screen and (max-width: 240px) {
}
