@import '../../assets/css/global_variables.scss';

.ideabot-chat-input {
  display: flex;
  align-items: center;
  border-top: 1px solid #D6D6D6;
  position: relative;
  height: 35px;
  padding: 0 10px;

  form {
    width: 100%;
    height: 100%;
    margin-right: 40px;
  }

  textarea {
    flex: 1;
    // padding: 2px 0px 10px 0px;
    border-radius: 5px;
    border: none;
    // margin-right: 10px;
    font-family: 'Manrope', sans-serif;
    // line-height: 1.5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #333333;
    resize: none;

    width: 100%;
    height: 50%;
    margin-top: 8px;

    &:focus-visible {
      border: none;
    }

    &:focus {
      outline: none !important;
    }
  }

  button {
    background-color: white;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 1px;
    right: 8px;
    .ideabot-chat-message-avatar{
      width: 24px;
      height:auto;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  button:hover {
    background-color: white;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    textarea {
      font-size: 16px !important;
      
      &::placeholder {
        font-size: 14px !important;
      }
    }
  }

  @media (min-width: 768px) {
    textarea {
      font-size: 12px !important;

      &::placeholder {
        font-size: 12px !important;
      }
    }
  }
}