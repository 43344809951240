.ideabot-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 28px;
	border-top: 1px solid #d6d6d6;
	position: relative;

	.ideabot-chat-footer-powered-by {
		width: 90px;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.ideabot-chat-footer-version {
		color: #53575f;
		font-family: "Manrope";
		font-style: normal;
		font-weight: 500;
		font-size: 9px;
		line-height: 22px;
		position: absolute;
		right: 25px;
	}
}
