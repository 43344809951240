@import "../assets/css/global_variables.scss";

.preview-content {
	// width: 100%;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: center;
	display: flex;
	// justify-content: space-between;
	flex-direction: row;
}

.header-top {
	background-color: white;
}

.preview-content-thin-column {
	// display: flex;
	padding: 15px 20px;
	// width: 50%;
	// height: 100vh;
	// align-items: center;
	// justify-content: center;
	background-color: #f5fcff;
	// position: fixed;
	// right: 0;
	// bottom: 0;
	// top: 0;
}

.preview-content-fat-column {
	display: flex;
	padding: 10px;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: -75px;
}

// .preview-content > * {
//   width: 100%;
// }

// @media (min-width: 992px) {
//   .preview-content > * {
//       width:calc(100% / 3);
//    }
//  }

// styles
.ideabot-chatBtn {
	display: flex;
	width: 60px;
	height: 60px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}

.ideabot-chatBtn img {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.ideabot-chat-widget {
	// width: 374px;
	// position: fixed;
	width: 100%;
	height: 100%;
	bottom: 20px;
	right: 20px;
	background-color: $widget-bg-color;
	border-radius: 24px;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	transition: height 0 -3s ease-in-out;
	overflow: hidden;
}

.ideabot-chat-messages {
	flex: 1;
	flex-direction: column;
	padding: 10px;
	overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
	.ideabot-chat-widget {
		height: 450px;
		margin-top: 25px;
	}
}

@media only screen and (max-width: 600px) {
	.ideabot-chat-widget.open {
		width: 330px;
	}
}

@media only screen and (max-width: 375px) {
	.ideabot-chat-widget.open {
		width: 301px;
		height: 490px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 340px) {
	.ideabot-chat-widget.open {
		width: 280px;
		height: 440px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 280px) {
	.ideabot-chat-widget.open {
		width: 245px;
		height: 440px;
	}

	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span {
		font-size: 12px;
	}
}

.ideabot-chat-widget {
	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: grey;
	}
}
