@import '../../assets/css/global_variables.scss';

// Styles

.ideabot-chat-messages {
	padding: 15px 15px;
	.ideabot-chat-message-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		.ideabot-initiative-message {
			margin-left: 15px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
		.ideabot-user-message-row {
			display: flex;
			flex: 1;
			flex-direction: row-reverse;
			.ideabot-avatar-wrapper {
				display: flex;
				position: relative;
				background-color: #040044;
				img {
					width: 25px;
					position: absolute;
					top: 5px;
					right: 5px;
				}
			}
			.ideabot-user-message {
				max-width: 80%;
				background-color: #f4f4f4;
				color: #040044;
				align-self: flex-end;
				border-radius: 24px 0px 24px 24px;
				margin: 15px 15px 0px 0px;
				padding: 10px 15px;
				word-wrap: break-word;
				p {
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					color: #333333;
					opacity: 0.9;
				}
			}
		}
		.ideabot-bot-message-row {
			display: flex;
			flex: 1;
			flex-direction: row;
			// padding: 10px 0px;
			.ideabot-avatar-wrapper {
				display: flex;
				background-color: #040044;
				position: relative;
				img {
					width: 25px;
					position: absolute;
					top: 5px;
					left: 4px;
				}
			}
			.ideabot-bot-message {
				white-space: pre-line;
				display: flex;
				background-color: #c9fff8;
				color: #040044;
				align-self: flex-end;
				border-radius: 0px 24px 24px 24px;
				margin: 15px 0px 0px 15px;
				padding: 10px 15px;
				max-width: 100%;
				overflow: auto;
				p {
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					color: #333333;
				}
			}
			.ideabot-bot-chart-message {
				display: flex;
				background-color: #c9fff8;
				color: #040044;
				align-self: flex-end;
				border-radius: 0px 24px 24px 24px;
				margin: 15px 0px 0px 15px;
				padding: 10px 15px;
				max-width: 100%;
				min-width: 50%;
				overflow: auto;
				p {
					font-family: "Manrope", sans-serif;
					line-height: 1.5;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
}

@media only screen and (max-width: 375px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 340px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}

@media only screen and (max-width: 280px) {
	.ideabot-chat-message-wrapper
		.ideabot-bot-message-row
		.ideabot-bot-message
		span,
	.ideabot-chat-message-wrapper
		.ideabot-user-message-row
		.ideabot-user-message
		span {
		font-size: 12px;
	}
}
